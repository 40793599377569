import RequestModel from '@apis/models/RequestModel';
import { createCancelToken } from '@apis/ServiceController';
import Pagination from '@components/Pagination';
import SEO from '@components/SEO';
import IconArrow2 from '@icons/IconArrow2';
import { wishlistService } from '@services/wishlistService';
import Wishlist from '@views/profile/Wishlist/Wishlist';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

const WishlistLayout = memo(() => {
   const [filter, setFilter] = useState({
      page: 1,
      pageSize: 24,
      orderBy: 'created_at',
      sortType: 'DESC',
   });
   const [data, setData] = useState({
      content: [],
      totalRows: 0,
      totalPages: 1,
   });
   const [loading, setLoading] = useState(true);
   const cancelToken = useRef();

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
      });
   }, []);

   useEffect(() => {
      if (cancelToken.current) {
         cancelToken.current.cancel();
      }
      setLoading(true);
      cancelToken.current = createCancelToken();
      const loadProducts = async () => {
         const requestBody = new RequestModel([filter]);
         const responseData = await wishlistService.getWishlistMatrix(
            requestBody,
            cancelToken.current?.token
         );
         if (responseData.isSuccess) {
            const { content, totalRows, totalPages } = responseData.data;
            setData((prevData) => ({
               ...prevData,
               content: content || [],
               totalRows: totalRows,
               totalPages: totalPages,
            }));
         }
         setLoading(false);
      };
      loadProducts();

      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [filter]);

   const onPaginationChange = useCallback((page, pageSize) => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
      });
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: page || prevFilter.page,
         pageSize: pageSize || prevFilter.pageSize,
      }));
   }, []);

   const reload = useCallback(() => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: 1,
      }));
   }, []);

   return (
      <>
         <SEO title={'Wishlist'} />
         <div className="profile-wishlist">
            <div className="title">
               <span>MY WISHLIST</span>
               <span className="s-arrow animate__animated animate__rotateInDownLeft animate__slow">
                  <IconArrow2 />
               </span>
            </div>
            <Wishlist data={data.content} loading={loading} reload={reload} />
            {data.totalPages > 1 && (
               <div className="profile-wishlist__pagination">
                  <Pagination
                     page={filter.page}
                     pageSize={filter.pageSize}
                     total={data.totalRows}
                     onChange={onPaginationChange}
                  />
               </div>
            )}
         </div>
      </>
   );
});

export default WishlistLayout;
