import ProductItemSkeleton from '@skeleton/skeleton-components/ProductItemSkeleton';
import { functionUtils } from '@utils/function';
import { Col, Row } from 'antd';
import React, { memo } from 'react';
import FavoriteItem from './FavoriteItem';
import { Link } from 'gatsby';
import constant from '@utils/constant';

const Wishlist = memo(({ data, loading, reload }) => {
   const renderSkeleton = () => {
      return (
         <>
            {Array.from({ length: 3 }, (_, idx) => (
               <Col lg={8} md={8} sm={24} xs={24} key={idx}>
                  <ProductItemSkeleton />
               </Col>
            ))}
         </>
      );
   };

   return (
      <div className="product-list profile-wishlist__matrix">
         <Row gutter={[30, 60]}>
            {loading && data?.length === 0 && renderSkeleton()}
            {data.map((item) => (
               <Col lg={8} md={8} sm={24} xs={24} key={item.uid}>
                  <FavoriteItem
                     favoriteUid={item.uid}
                     {...(item.product || {})}
                     status={functionUtils.getProductDisplayStatus(item)}
                     reload={reload}
                  />
               </Col>
            ))}
         </Row>
         {!loading && data.length === 0 && (
            <div className="empty">
               <label>Your wishlist is empty.</label>
            </div>
         )}
      </div>
   );
});

export default Wishlist;
