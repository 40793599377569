import { Link } from 'gatsby';
import React, { memo, useMemo } from 'react';
import _snakeCase from 'lodash/snakeCase';
import LazyImage from '@components/LazyImage';
import Currency from '@components/Currency';
import Rate from '@views/product-item/Rate';
import { FileSize } from '@utils/enums/FileSize';
import { wishlistService } from '@services/wishlistService';

const FavoriteItem = memo((props) => {
   const {
      favoriteUid,
      slug,
      status,
      name,
      price,
      local_price,
      rate,
      reviews,
      attachments,
      reload,
   } = props;
   const [submitting, setSubmitting] = React.useState(false);

   const imgUrl = useMemo(() => {
      let url = '';
      if (attachments?.length) {
         url = attachments[0]?.formats?.[FileSize.MEDIUM] || '';
      }
      return url;
   }, [attachments]);

   const remove = async () => {
      await setSubmitting(true);
      const response = await wishlistService.deleteFromWishlist([favoriteUid]);
      console.log(response);
      if (response.isSuccess) {
         reload();
      }
      setSubmitting(false);
   };

   return (
      <div className="product-card-item">
         <Link className="product-item-link" to={`/${slug}`}>
            <div className="product-card__preview">
               <div className="mask">
                  {status && (
                     <div className={`current-status ${_snakeCase(status)}`}>
                        {status}
                     </div>
                  )}
                  <div className="quick-view">
                     <span>VIEW DETAIL</span>
                  </div>
               </div>
               <LazyImage className="product-card__img" src={imgUrl} title={name} />
            </div>
         </Link>
         <div className="card-info">
            <label className="product-name" title={name}>
               {name}
            </label>
            <span className="price">
               <Currency price={price} localPrice={local_price} />
            </span>
            <span className="sub-info">
               <Rate point={rate} />
               <span className="count-review">{reviews} reviews</span>
            </span>
         </div>
         <div className="profile-wishlist__item-actions">
            <button
               className="btn-vape h40 outline__red"
               type="button"
               onClick={remove}
               disabled={submitting}
            >
               Remove
            </button>
            <Link to={`/${slug}`} className="common-button" type="button">
               BUY NOW
            </Link>
         </div>
      </div>
   );
});

export default FavoriteItem;
