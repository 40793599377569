import { apiConstant } from '@apis/constant';
import { tokenUtil } from '@utils/token';
import { fetch } from '@apis/ServiceController';
import _forIn from 'lodash/forIn';

const addProductToWishlist = async (uid, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.ADD_PRODUCT_TO_WISHLIST,
      data: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const deleteFromWishlist = async (uids, cancelToken) => {
   return fetch({
      method: 'delete',
      url: apiConstant.DELETE_FROM_WISHLIST,
      data: {
         uids,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const getWishlistMatrix = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_WISHLIST_MATRIX,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

export const wishlistService = {
   addProductToWishlist,
   deleteFromWishlist,
   getWishlistMatrix,
};
